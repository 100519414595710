import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import Button from '../Button';
import Image from '../Image';
import styles from './News.module.scss';

const News = ({ title = '', button = {}, highlight = {}, items = [] }) => {
    const { image, link, label, title: highlightTitle, excerpt } = highlight;

    const hasHighlight =
        !!image || !!link || !!label || !!highlightTitle || !!excerpt;

    return (
        <section className={styles['News']}>
            <div className={styles['News__Header']}>
                <h2 className={styles['News__Title']}>{title}</h2>
                <div className={styles['News__Button']}>
                    {button && <Button link={button} />}
                </div>
            </div>
            {hasHighlight && (
                <article className={styles['News__Highlight']}>
                    {!!link && (
                        <a
                            className={styles['News__HighlightLink']}
                            target={link.target}
                            href={link.href}>
                            <span className="sr-only">{highlightTitle}</span>
                        </a>
                    )}
                    <div className={styles['News__Inner']}>
                        <LazyLoad once>
                            <div className={styles['News__ImageContainer']}>
                                {!!image && image.url && (
                                    <Image
                                        {...{ image }}
                                        sizes={'(min-width: 1680px) 828px'}
                                    />
                                )}
                            </div>
                        </LazyLoad>
                        <div className={styles['News__HighlightContent']}>
                            <span className={styles['News__Label']}>
                                {label}
                            </span>
                            <h3
                                className={styles['News__NewsTitle']}
                                aria-hidden>
                                {highlightTitle}
                            </h3>
                            <p className={styles['News__Excerpt']}>{excerpt}</p>
                        </div>
                    </div>
                </article>
            )}
            <div className={styles['News__Feed']}>
                <div className={styles['News__List']}>
                    {items.map((item, index) => (
                        <NewsItem key={index} {...item} />
                    ))}
                </div>
            </div>{' '}
            {button && (
                <div className={styles['News__ButtonMobile']}>
                    <Button link={button} />
                </div>
            )}
        </section>
    );
};

News.propTypes = {
    title: PropTypes.string,
    image: PropTypes.shape({
        renditions: PropTypes.shape({
            mobile: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            mobile2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        }),
        focal: PropTypes.shape({
            x: PropTypes.string,
            y: PropTypes.string,
        }),
        altText: PropTypes.string,
    }),
    highlight: PropTypes.shape({
        link: PropTypes.shape({
            href: PropTypes.string,
            target: PropTypes.string,
        }),
        label: PropTypes.string,
        title: PropTypes.string,
        excerpt: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.shape({
                href: PropTypes.string,
                target: PropTypes.string,
            }),
            label: PropTypes.string,
            title: PropTypes.string,
        })
    ),
};

const NewsItem = ({ link = '', label = '', title = '' }) => {
    return (
        <div className={styles['News__Item']}>
            <span className={styles['News__Label']}>{label}</span>
            <a href={link.href} target={link.target}>
                <span className={styles['News__NewsTitle']}>{title}</span>
            </a>
        </div>
    );
};

NewsItem.propTypes = {
    href: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
};

export default News;
