import React, { useState } from 'react';
import classNames from 'classnames';
import NextImage from 'next/image';
import styles from './Image.module.scss';

const Image = ({
    sizes = '',
    image = {},
    priority = false,
    quality = null,
}) => {
    if (Object.keys(image).length === 0) {
        return null;
    }

    const [loaded, setLoaded] = useState(false);

    const { url, focal, altText } = image;

    const classes = classNames(styles['Image__FadeIn'], {
        [styles['Image__FadeIn--Loaded']]: loaded,
    });

    return (
        <NextImage
            className={classes}
            style={{
                objectFit: 'cover',
                objectPosition: `${focal.x} ${focal.y}`,
            }}
            src={url}
            alt={altText}
            fill
            onLoad={() => setLoaded(true)}
            priority={priority}
            quality={quality}
            sizes={sizes}
        />
    );
};

Image.propTypes = {};

export default Image;
